import useSWR from 'swr';
import { TimeSlots } from 'api/types';
import dayjs from 'dayjs';

export const useAvailability = (date: dayjs.Dayjs | undefined) => {
  const { data, error } = useSWR<TimeSlots>(
    date ? `/api/availability/${date.toJSON()}` : null
  );

  return {
    timeSlots: data,
    isLoading: !data && !error,
    error: error,
  };
};
