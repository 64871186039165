import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Navigation } from 'components/Navigation';
import { MotionBox } from 'components/MotionBox';

interface MainProps {
  children: React.ReactNode;
}
export const Main = (props: MainProps) => {
  return (
    <Box w="100%">
      <Flex
        flexDirection="column"
        py={['8', '12', '16', '24', '32']}
        px={['4', '8', '16', '24', '32']}
        maxW="1200px"
        minH="100vh"
        margin="auto"
      >
        <Navigation />
        <MotionBox
          as="main"
          display="flex"
          pt="8"
          flex="1"
          flexDirection="column"
          minH="100%"
          position="relative"
        >
          {props.children}
        </MotionBox>
      </Flex>
    </Box>
  );
};
