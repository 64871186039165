import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import Link from 'next/link';

interface LinkButtonProps extends ButtonProps {
  href: string;
}

export const LinkButton = ({ href, ...rest }: LinkButtonProps) => {
  const ButtonComponent = (
    <Button
      as={rest.disabled ? 'button' : 'a'}
      colorScheme="forest"
      fontWeight="normal"
      size="lg"
      width="full"
      minH="48px"
      mt="8"
      onClick={(e) => {
        if (rest.disabled) {
          e.preventDefault();
        }
      }}
      {...rest}
    />
  );

  return rest.disabled ? (
    ButtonComponent
  ) : (
    <Link href={href} passHref>
      {ButtonComponent}
    </Link>
  );
};

