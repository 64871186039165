import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { Admission, GiftCardInfo, PromoCodeInfo } from 'api/types';
import dayjs from 'dayjs';

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  personalId?: string;
  reference: string | undefined;
}

export interface AdmissionMap {
  [Admission.SingleAdmission]: number;
  [Admission.YouthAdmission]: number;
  [Admission.ChildAdmission]: number;
  [Admission.ElderlyAdmission]: number;
  [Admission.DisabledAdmission]: number;
  [Admission.YoungAdultAdmission]: number;
  [Admission.SingleAdmissionWithDrink]: number;
}

export const baseAdmissionMap: AdmissionMap = {
  [Admission.SingleAdmission]: 0,
  [Admission.YouthAdmission]: 0,
  [Admission.ChildAdmission]: 0,
  [Admission.ElderlyAdmission]: 0,
  [Admission.DisabledAdmission]: 0,
  [Admission.YoungAdultAdmission]: 0,
  [Admission.SingleAdmissionWithDrink]: 0,
};

interface Store {
  guests: AdmissionMap;
  dateOfArrival?: dayjs.Dayjs;
  timeOfArrival?: dayjs.Dayjs;
  userInfo?: UserInfo;
  prices: AdmissionMap;
  giftCard?: GiftCardInfo;
  promoCode?: PromoCodeInfo;
  adultWithDrink: boolean;
  hasAdultCompany: boolean;
  setGuest: (key: Admission, value: number) => void;
  setGuests: (guests: AdmissionMap) => void;
  setDateOfArrival: (date?: dayjs.Dayjs) => void;
  setTimeOfArrival: (date?: dayjs.Dayjs) => void;
  setUserInfo: (info: UserInfo) => void;
  setPrice: (key: Admission, value: number) => void;
  setPrices: (prices: AdmissionMap) => void;
  setGiftCard: (giftCard?: GiftCardInfo) => void;
  setPromoCode: (promoCode?: PromoCodeInfo) => void;
  setAdultWithDrink: (adultWithDrink: boolean) => void;
  setAdultCompany: (is_adult_company: boolean) => void;
}

export const useStore = create<Store>()(
  devtools(set => ({
    guests: baseAdmissionMap,
    prices: baseAdmissionMap,
    userInfo: undefined,
    dateOfArrival: undefined,
    timeOfArrival: undefined,
    timeSlotPrice: undefined,
    giftCard: undefined,
    promoCode: undefined,
    adultWithDrink: false,
    hasAdultCompany: false,
    setGuest: (key: Admission, value: number) => {
      set(state => ({
        guests: {
          ...state.guests,
          [key]: value,
        },
        dateOfArrival: undefined,
        timeOfArrival: undefined,
      }));
    },
    setGuests: (guests: AdmissionMap) => {
      set(() => ({
        guests,
      }));
    },
    setDateOfArrival: (dateOfArrival?: dayjs.Dayjs) => {
      set(() => ({
        dateOfArrival,
        timeOfArrival: undefined,
      }));
    },
    setTimeOfArrival: (timeOfArrival?: dayjs.Dayjs) => {
      set(() => ({
        timeOfArrival,
      }));
    },
    setUserInfo: (info: UserInfo) => {
      set(() => ({
        userInfo: {
          ...info,
        },
      }));
    },
    setPrice: (key: Admission, value: number) => {
      set(state => ({
        prices: {
          ...state.prices,
          [key]: value,
        },
      }));
    },
    setPrices: (prices: AdmissionMap) => {
      set(() => ({
        prices,
      }));
    },
    setGiftCard: (giftCard?: GiftCardInfo) => {
      set(() => ({
        giftCard,
      }));
    },
    setPromoCode: (promoCode?: PromoCodeInfo) => {
      set(() => ({
        promoCode,
      }));
    },
    setAdultWithDrink: (adultWithDrink: boolean) => {
      set(() => ({
        adultWithDrink,
      }));
    },
    setAdultCompany: (is_adult_company: boolean) => {
      set(() => ({
        hasAdultCompany: is_adult_company,
      }));
    },
  }))
);
