import React from 'react';
import { useStore } from 'store';

export const useAdmissionCount = () => {
  const guests = useStore(state => state.guests);

  return React.useMemo(
    () =>
      Object.values(guests).reduce(
        (previous, current) => previous + current,
        0
      ),
    [guests]
  );
};
