import React, { useEffect } from 'react';
import { HStack } from '@chakra-ui/react';
import { NavigationLink } from './NavigationLink';
import { useStore } from 'store';
import { useAdmissionCount } from 'hooks';

export const Navigation = () => {
  const dateOfArrival = useStore(state => state.dateOfArrival);
  const timeOfArrival = useStore(state => state.timeOfArrival);
  const admissionCount = useAdmissionCount();
  const has_adult_with_company = useStore(state => state.hasAdultCompany);


  return (
    <HStack spacing="4">
      <NavigationLink
        href="/booking/guests"
        title="Visitors"
        canNavigate={true}
      />
      <NavigationLink
        href="/booking/date"
        title="Date"
        canNavigate={Boolean(admissionCount) && has_adult_with_company}
      />
      <NavigationLink
        href="/booking/time"
        title="Time"
        canNavigate={Boolean(dateOfArrival)}
      />
      <NavigationLink
        href="/booking/checkout"
        title="Checkout"
        canNavigate={Boolean(timeOfArrival)}
      />
    </HStack>
  );
};
