import React from 'react';
import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { IconMinus } from '@tabler/icons';
import { useStore } from 'store';
import { Admission } from 'api/types';

interface DecrementButtonProps extends IconButtonProps {
  storeKey: Admission;
}
export const DecrementButton = ({
  storeKey,
  ...rest
}: DecrementButtonProps) => {
  const [value, setGuest] = useStore(state => [
    state.guests[storeKey],
    state.setGuest,
  ]);

  const decrement = () => {
    if (value === 0) {
      return;
    }

    setGuest(storeKey, value - 1);
  };

  return (
    <IconButton
      colorScheme="forest"
      icon={<IconMinus />}
      onClick={() => decrement()}
      disabled={value === 0}
      borderRadius="full"
      {...rest}
    />
  );
};
