import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export const SubmitButton = (props: ButtonProps) => {
  return (
    <Button
      colorScheme="forest"
      fontWeight="normal"
      size="lg"
      width="full"
      minH="48px"
      mt="4"
      type="submit"
      {...props}
    />
  );
};
