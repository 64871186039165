import { Admission } from 'api/types';
import { map } from 'lodash';
import { AdmissionMap } from 'store';

export const calculateTotal = (guests: AdmissionMap, prices: AdmissionMap) => {
  let total = map(guests, (v, k) => {
    const key = k as keyof typeof guests;

    return guests[key] * prices[key];
  }).reduce((previous, current) => previous + current, 0);

  return total > 0 ? total : 0;
};

export const admissionToReadableName = (admission: Admission): string => {
  switch (admission) {
    case Admission.SingleAdmission:
      return 'Single Admission';

    case Admission.YouthAdmission:
      return 'Youth Admission';

    case Admission.ChildAdmission:
      return 'Child Admission';

    case Admission.ElderlyAdmission:
      return 'Elderly Admission';

    case Admission.DisabledAdmission:
      return 'Disabled Admission';

    case Admission.YoungAdultAdmission:
      return 'Young Adult Admission';

    case Admission.SingleAdmissionWithDrink:
      return 'Single Admission With Drink';
  }
};
