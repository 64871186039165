import useSWR from 'swr';
import { AvailabilityRange } from 'api/types';
import dayjs from 'dayjs';

export const useAvailabilityRange = (month: dayjs.Dayjs) => {
  const { data, error } = useSWR<AvailabilityRange>(
    month ? `/api/availability?month=${month.toJSON()}` : null
  );

  return {
    availabilityRange: data,
    isLoading: !data && !error,
    error: error,
  };
};
