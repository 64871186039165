import React, { useEffect } from 'react';
import { Box, Heading, HStack, Spacer, Text } from '@chakra-ui/react';
import { LinkButton } from 'components/Button';
import { Title } from 'components/Title';
import { Main } from 'components/Layout';
import { Admission } from 'api/types';
import { GuestPicker } from 'components/GuestPicker';
import { AdmissionMap, useStore } from 'store';
import Link from 'next/link';

const GuestsPage = () => {
  const guests = useStore(state => state.guests);
  const setAdultWithDrink = useStore(state => state.setAdultWithDrink);
  const setUserAdmissions = useStore(state => state.setGuests);
  const setDateOfArrival = useStore(state => state.setDateOfArrival);
  const setTimeOfArrival = useStore(state => state.setTimeOfArrival);
  const setAdultCompany = useStore(state => state.setAdultCompany);

  useEffect(() => {
    if (guests[Admission.SingleAdmissionWithDrink] === 1) {
      setUserAdmissions({
        ...guests,
        [Admission.SingleAdmissionWithDrink]: 0,
      });
      setAdultWithDrink(false);
      setDateOfArrival(undefined);
      setTimeOfArrival(undefined);
    }
  }, [guests, setUserAdmissions, setAdultWithDrink, setDateOfArrival, setTimeOfArrival]);

  // New useEffect to update hasAdultCompany state
  useEffect(() => {
    const hasAdult = Object.keys(guests).some(key =>
      [Admission.SingleAdmission, Admission.DisabledAdmission, Admission.ElderlyAdmission, Admission.SingleAdmissionWithDrink].includes(key as Admission) && guests[key as Admission] > 0
    );
    setAdultCompany(hasAdult);
  }, [guests, setAdultCompany]);

  const handleAdultWithDrinkClick = () => {
    setUserAdmissions({
      [Admission.SingleAdmission]: 0,
      [Admission.YouthAdmission]: 0,
      [Admission.ChildAdmission]: 0,
      [Admission.ElderlyAdmission]: 0,
      [Admission.DisabledAdmission]: 0,
      [Admission.YoungAdultAdmission]: 0,
      [Admission.SingleAdmissionWithDrink]: 1,
    });
    setAdultWithDrink(true);
  };

  const handleContinueClick = () => {
    setAdultWithDrink(false);
  };

  const hasAdultCompany = (guests: AdmissionMap): boolean => {
    const adultTickets: Admission[] = [
      Admission.SingleAdmission,
      Admission.DisabledAdmission,
      Admission.ElderlyAdmission,
      Admission.SingleAdmissionWithDrink,
    ];

    return adultTickets.some(ticket => guests[ticket]);
  };

  return (
    <React.Fragment>
      <Title>Guests</Title>
      <Heading size="2xl" color="forest.700">
        How many guests are visiting?
      </Heading>
      <Text px="4" pt="4">
        Children that are 5 years old or younger get free admission but share a
        locker with their guardian.
      </Text>
      <GuestPicker admissionType={Admission.SingleAdmission} pt="6">
        Ages 16 - 67 years old
      </GuestPicker>
      <GuestPicker admissionType={Admission.YoungAdultAdmission} pt="6">
        Ages 12 - 15 years old
      </GuestPicker>
      <GuestPicker admissionType={Admission.YouthAdmission} pt="6">
        Ages 6 - 11 years old
      </GuestPicker>
      <GuestPicker admissionType={Admission.ChildAdmission} pt="6">
        Ages 0 - 5 years old
      </GuestPicker>
      <Box mt="12">
        <HStack>
          <Heading size="lg" pr="4">
            Elderly &amp; disabled persons
          </Heading>
          <Box h="2px" background="forest.100" flex="1" />
        </HStack>
        <Text px="4" py="4">
          People with disabilities and people above the age of 67 get discounts
          on admission to the Forest Lagoon. If there are any disabled people
          and/or elderly people visiting, please add them here.
        </Text>
      </Box>
      <GuestPicker admissionType={Admission.ElderlyAdmission} pt="6">
        Ages 67 years and older
      </GuestPicker>
      <GuestPicker admissionType={Admission.DisabledAdmission} pt="6">
        People with legal disabilities
      </GuestPicker>
      <br></br>
      <Text fontSize="lg" color="green.400" mb="8" style={{ fontSize: '26px' }}>
        Áttu gjafabréf fyrir fullorðinn með drykk? Sentu email á{' '}
        <Link href="mailto:info@forestlagoon.is" passHref>
          <a style={{ textDecoration: 'underline' }}>info@forestlagoon.is</a>
        </Link>
      </Text>

      <Spacer />
      <LinkButton href="/booking/date" disabled={!hasAdultCompany(guests)} onClick={handleContinueClick}>
        Continue
      </LinkButton>
    </React.Fragment>
  );
};

GuestsPage.getLayout = function getLayout(page: React.ReactElement) {
  return <Main>{page}</Main>;
};

export default GuestsPage;
