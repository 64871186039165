import Head from 'next/head';
import React from 'react';

interface TitleProps {
  children: React.ReactNode;
}
export const Title = (props: TitleProps) => (
  <Head>
    <title>{`Forest Lagoon | ${props.children}`}</title>
  </Head>
);
