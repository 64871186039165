import { Heading, propNames } from '@chakra-ui/react';
import { MotionBox } from 'components/MotionBox';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

interface NavigationLinkProps {
  title: string;
  href: string;
  canNavigate: boolean;
}

interface WrapLinkProps extends Omit<NavigationLinkProps, 'title'> {
  children: React.ReactNode;
}

const WrapLink = ({ canNavigate, href, children }: WrapLinkProps) => (
  <React.Fragment>
    {canNavigate ? (
      <Link href={href} passHref>
        <a>{children}</a>
      </Link>
    ) : (
      children
    )}
  </React.Fragment>
);

export const NavigationLink = (props: NavigationLinkProps) => {
  const router = useRouter();

  const isActive = router.pathname === props.href;

  return (
    <MotionBox>
      <WrapLink canNavigate={props.canNavigate} href={props.href}>
        <Heading
          fontSize={['1rem', '1.25rem']}
          fontWeight="bold"
          color="forest.600"
          opacity={isActive ? 1 : 0.4}
          borderBottom="1px"
          borderColor="gray.300"
          pb="1"
          px="1"
          transition="all 0.3s ease-in-out"
          userSelect="none"
        >
          {props.title}
        </Heading>
      </WrapLink>
    </MotionBox>
  );
};
