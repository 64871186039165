import React from 'react';
import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { IconPlus } from '@tabler/icons';
import { useStore } from 'store';
import { Admission } from 'api/types';

interface IncrementButtonProps extends IconButtonProps {
  storeKey: Admission;
}
export const IncrementButton = ({
  storeKey,
  ...rest
}: IncrementButtonProps) => {
  const [value, setGuest] = useStore(state => [
    state.guests[storeKey],
    state.setGuest,
  ]);

  const increment = () => {
    if (value === 30) {
      return;
    }

    setGuest(storeKey, value + 1);
  };

  return (
    <IconButton
      colorScheme="forest"
      icon={<IconPlus />}
      onClick={() => increment()}
      disabled={value === 30}
      borderRadius="full"
      {...rest}
    />
  );
};
