import { Admission } from 'api/types/availability';

export enum PromoCodeStatus {
  Issued = 'Issued',
  Used = 'Used',
}

export interface PromoCodeResponseDto {
  '@odata.context': string;
  '@odata.etag': string;
  number: string;
  promoCodeName: string;
  amount: number;
  // status: PromoCodeStatus;
  reasonBlocked: string;
  /**
   * Date string in dd.MM.yyyy format
   * e.g 05.10.2020
   */
  dateCreated: string;
  validTo: string;
  Message: string;
  externalDocumentNo: string;
  shippingMethod: string;
  customerName: string;
  customerEmail: string;
  recipientName: string;
  recipientEmail: string;
  recipientPhoneNumber: string;
  recipientAddress: string;
  recipientAddress2: string;
  recipientPostCode: string;
  recipientCountryCode: string;
  language: string;
  promoCodeLines: PromoCodeLineDto[];
}

export interface PromoCodeLineDto {
  '@odata.etag': string;
  promoCodeNo: string;
  lineNo: number;
  Number: Admission;
  Quantity: number;
  Balance: number;
}

export interface PromoCodeValue {
  '@odata.etag': string;
  promoCode: string;
  productNo: string;
  price: number;
  discountAmount: number;
}

export interface PromoCodeOutstandingBalanceDto {
  '@odata.context': string;

  /**
   * Outstanding balance on order after applying promo code
   */
  value: string;
}

export interface PromoCodeInfo {
  name: string;
  code: string;
  // status: PromoCodeStatus;
  amount: number;
  validTo: string;
  lines: PromoCodeInfoLine[];
}

export interface PromoCodeInfoLine {
  promoCode: string;
  admissionType: Admission;
  balance: number;
  discount: number;
}
