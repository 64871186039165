import { Admission } from 'api/types';
import dayjs from 'dayjs';

export const formatTime = (date: dayjs.Dayjs): string => {
  return date.format('HH:mm');
};

export const formatDate = (date: dayjs.Dayjs): string => {
  return date.format('YYYY-MM-DD');
};

export const formatDateLong = (date: dayjs.Dayjs): string => {
  return date.format('dddd, MMMM D');
};

export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('is-IS', {
    style: 'currency',
    currency: 'ISK',
  }).format(value);
};

export const pluralizeAdmission = (
  value: number,
  admission: Admission
): string => {
  let singular = admissionToName(admission);

  if (value === 1) {
    return singular;
  }

  if (singular === 'child') {
    return 'children';
  }

  if (singular === 'adult with drink') {
    return 'adults with drink';
  }
  return `${singular}s`;
};

export const admissionToName = (admission: Admission): string => {
  switch (admission) {
    case Admission.SingleAdmission:
      return 'adult';
    case Admission.YoungAdultAdmission:
      return 'young adult';
    case Admission.YouthAdmission:
      return 'youth';
    case Admission.ChildAdmission:
      return 'child';
    case Admission.ElderlyAdmission:
      return 'elder';
    case Admission.DisabledAdmission:
      return 'disabled person';
    case Admission.SingleAdmissionWithDrink:
      return 'adult with drink';
  }
};
