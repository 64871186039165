import React from 'react';
import { Center } from '@chakra-ui/react';

interface SimpleProps {
  children: React.ReactNode;
}
export const Simple = (props: SimpleProps) => {
  return (
    <Center h="100vh" w="100vw">
      {props.children}
    </Center>
  );
};
