import React from 'react';
import { Heading, Box, HStack, Text, BoxProps } from '@chakra-ui/react';
import { Admission } from 'api/types';
import { IncrementButton, DecrementButton } from 'components/Button';
import { useStore } from 'store';
import { pluralizeAdmission } from 'util/format';

interface GuestPickerProps extends BoxProps {
  admissionType: Admission;
  children?: React.ReactNode;
}
export const GuestPicker = ({
  admissionType,
  children,
  ...rest
}: GuestPickerProps) => {
  const guests = useStore(state => state.guests);

  return (
    <Box {...rest}>
      <HStack flexWrap="wrap">
        <Heading minW="5">{guests[admissionType]}</Heading>
        <Heading flex="1" lineHeight="1">
          {pluralizeAdmission(guests[admissionType], admissionType)}
        </Heading>
        <Box>
          <DecrementButton
            aria-label="Remove one guest"
            storeKey={admissionType}
          />
          <IncrementButton
            aria-label="Add one guest"
            storeKey={admissionType}
            mx="3"
          />
        </Box>
      </HStack>
      {children ? (
        <Text px="4" color="forest.700">
          {children}
        </Text>
      ) : null}
    </Box>
  );
};
