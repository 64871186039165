import { AdmissionMap } from 'store';

export enum Admission {
  /**
   * People above the age of 16 years old
   */
  SingleAdmission = 'APKG000001',
  /**
   * People between the ages of 6 and 12 years old
   */
  YouthAdmission = 'APKG000002',
  /**
   * People 5 years old or younger
   */
  ChildAdmission = 'APKG000003',
  /**
   * People above the age of 67
   */
  ElderlyAdmission = 'APKG000004',
  /**
   * People with disabilities
   */
  DisabledAdmission = 'APKG000005',
  /**
   * People between the ages of 12 and 16 years old
   */
  YoungAdultAdmission = 'APKG000021',
   /**
   * Admission with a drink, not sure why this is a seperate type
   */
   SingleAdmissionWithDrink = 'APKG000010',
}

export interface AvailabilityDto {
  '@odata.context': string;
  value: AvailabilityDtoValue[];
}

export interface AvailabilityDtoValue {
  '@odata.etag': string;
  Sequence_No: number;
  productNo: Admission;
  /**
   * Date string in `yyy-MM-dd` format
   *
   * e.g. `2022-06-01`
   */
  availabilityDate: string;
  /**
   * Time string in `kk:mm:ss` format
   *
   * e.g `10:00:00`
   */
  availabilityTime: string;
  availability: number;
  timeCaption: string;
  location: string;
  ICEAvailabilityprice: Array<{
    '@odata.etag': string;
    systemId: string;
    productNo: Admission;
    productName: string;
    price: number;
  }>;
}

export interface AvailabilityRangeDto {
  '@odata.context': string;
  value: Array<{
    '@odata.etag': string;
    sequenceNo: number;
    /**
     * Date string in `yyyy-MM-dd` format
     *
     * e.g. `2022-05-14`
     */
    availabilityDate: string;
    /**
     * Available slots for a particular day
     */
    availability: number;
  }>;
}

export interface Slot {
  time: string;
  availableSlots: number;
  prices: AdmissionMap;
}

export interface TimeSlots {
  date: string;
  slots: Slot[];
}

export interface TimeSlotAvailability {
  enabled: boolean;
  message?: String;
}

export interface AvailabilityRangeDay {
  availableSlots: number;
}

export interface AvailabilityRange {
  dateFrom: string;
  dateTo: string;
  availability: Record<string, AvailabilityRangeDay>;
}
