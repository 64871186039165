import { Admission } from 'api/types/availability';

export enum GiftCardStatus {
  Issued = 'Issued',
  Used = 'Used',
}

export interface GiftCardResponseDto {
  '@odata.context': string;
  '@odata.etag': string;

  /** Gift card code */
  number: string;

  /** Gift card name */
  giftCardName: string;

  /** Amount (will be 0 after usage) */
  amount: number;

  /** Gift card status */
  status: GiftCardStatus;

  /** Reason for being blocked */
  reasonBlocked: string;

  /**
   * Date string in dd.MM.yyyy format
   * e.g 05.10.2020
   */
  dateCreated: string;

  /** Date string (can be empty string) */
  validTo: string;

  /** Message to display, can be empty string, not in use. */
  Message: string;

  /** External document number, can be empty string, not in use. */
  externalDocumentNo: string;

  /** Shipping method, irrelevant to us */
  shippingMethod: string;

  /** Bought by this customer, not in use by us */
  customerName: string;

  /** Bought by this customer email, not in use by us */
  customerEmail: string;

  /** Issued to this customer, not in use by us */
  recipientName: string;

  /** Issued to this customer email, not in use by us */
  recipientEmail: string;

  /** Issued to this customer email, not in use by us */
  recipientPhoneNumber: string;

  /** Issued to this customer address, not in use by us */
  recipientAddress: string;

  /** Issued to this customer address second line, not in use by us */
  recipientAddress2: string;

  /** Issued to this customer postal code, not in use by us */
  recipientPostCode: string;

  /** Issued to this customer country code, not in use by us */
  recipientCountryCode: string;

  /** Language of gift card issuer */
  language: string;

  /**
   * THIS IS GONE!
   * @deprecated
   */
  giftCardLines?: GiftCardLineDto[];
}

export interface GiftCardLineDto {
  '@odata.etag': string;
  giftCardNo: string;
  lineNo: number;
  number: Admission;
  quantity: number;
  balance: number;
}

export interface GiftCardOutstandingBalanceDto {
  '@odata.context': string;

  /**
   * Outstanding balance on order after applying gift card
   */
  value: number;
}

export interface GiftCardInfo {
  giftCardName?: string;
  name: string;
  code: string;
  status: GiftCardStatus;
  amount: number;
  validTo: string;
  lines: GiftCardInfoLine[];
}

export interface GiftCardInfoLine {
  admissionType: Admission;
  quantity: number;
  balance: number;
}
