import dayjs from 'dayjs';
import { AdmissionMap, UserInfo } from 'store';
import { Admission } from './availability';

export enum Tender {
  Card = '3',
  GiftCard = '41',
  None= '',
}

export interface Booking {
  guests: AdmissionMap;
  dateOfArrival: dayjs.Dayjs;
  timeOfArrival: dayjs.Dayjs;
  userInfo: UserInfo;
  giftCardCode?: string;
  promoCode?: string;
}

export interface BookingResponse {
  referenceNumber: string;
  digitalSignature: string;
  bookingSignature: string;
  item: BookingResponseItem;
}

export interface BookingResponseItem {
  name: string;
  amount: number;
  discount: number;
}

export interface GiftcardRequest {
  code: string;
}

export interface GiftcardResponse {
  amount: number;
}

export interface PromocodeRequest {
  code: string;
}

export interface PromocodeResponse {
  amount: number;
}

export interface BookingDto {
  number?: string;
  customerSSN?: string;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  phoneNo: string;
  orderItems: BookingItemDto[];
  orderPayments?: BookingPaymentDto[];
  reference: string | undefined;
}

export interface BookingItemDto {
  productNo: Admission;
  productName: string;
  /**
   * Date time string in JSON format
   * e.g. 2022-05-13T14:28:38.473Z
   */
  availabilityDateTime: string;
  quantity: number;
  price: number;
  discountAmount: number;
}

export interface BookingPaymentDto {
  groupNo: string;
  /**
   * Date time string in JSON format
   * e.g. 2022-05-13T14:28:38.473Z
   */
  datePaid: string;
  amount: number;
  referenceNumber: string;
  //currencyCode?: string;
  authorizationCode: string;
  tenderType: Tender;
}

export interface BookingPaymentResponseDto extends BookingPaymentDto {
  '@odata.context': string;
  '@odata.etag': string;
  systemId: string;
}

export interface BookingResponseDto
  extends Pick<
    BookingDto,
    | 'number'
    | 'customerSSN'
    | 'customerFirstName'
    | 'customerLastName'
    | 'customerEmail'
  > {
  '@odata.context': string;
  '@odata.etag': string;
  systemId: string;
  orderId: number;
  itemCount: number;
  orderItems?: BookingItemDto[];
}

export interface BookingCollectionResponseDto {
  '@odata.context': string;
  value: BookingResponseDto[];
}

export interface ActivityBookingDto {
  orderId: number;
}

export interface ActivityBookingResponseDto {
  '@odata.context': string;
  /**
   * Also named groupNo
   */
  value: string;
}
