import React from 'react';
import { useStore } from 'store';
import { calculateTotal } from 'util/booking';

export const useBookingTotal = () => {
  const guests = useStore(state => state.guests);
  const prices = useStore(state => state.prices);

  return React.useMemo(() => calculateTotal(guests, prices), [guests, prices]);
};
